import logo from './logo512.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h4>
          SEJA BEM-VINDO!
        </h4>
        <p>Estamos construindo nossa página, breve ela estará disponivel.<br></br>
        Enquanto isso, se precisar nos chame no WhatsApp no link abaixo.👇🏼</p>
        <a
          className="WhatsApp"
          href="https://wa.me/5537998060444"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp - Mecânica 4 Tempos
        </a>
        <h2>PRECISOU DE MANUTENÇÃO? 4 TEMPOS É A SOLUÇÃO!!!</h2>
      </header>
    </div>
  );
}

export default App;
